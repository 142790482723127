import { render, staticRenderFns } from "./RegistroDePostulacion.vue?vue&type=template&id=8d9b9806&scoped=true"
import script from "./RegistroDePostulacion.vue?vue&type=script&lang=js"
export * from "./RegistroDePostulacion.vue?vue&type=script&lang=js"
import style0 from "./RegistroDePostulacion.vue?vue&type=style&index=0&id=8d9b9806&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "8d9b9806",
  null
  
)

export default component.exports